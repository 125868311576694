/* eslint-disable no-underscore-dangle */

import { compose } from "ramda";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
import api from "../utils/api";

const configureStore = (history, loggedUser) => {
  const isDevelopment = process.env.NODE_ENV !== "production";

  const composeEnhancers =
    isDevelopment &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  return createStore(
    reducers(loggedUser),
    undefined,
    composeEnhancers(
      applyMiddleware(
        thunk.withExtraArgument({
          api,
          document,
          getHtml2Canvas: () =>
            import(/* webpackChunkName: "html2canvas" */ "html2canvas").then(
              (module) => module.default
            ),
          history,
          storage: sessionStorage,
          window,
        })
      )
    )
  );
};

export default configureStore;
